export function singularHardwareType(hardwareType) {
  switch (hardwareType) {
    case 'motors':
      return 'motor';
    case 'gearboxes':
      return 'gearbox';
    case 'encoders':
      return 'encoder';
    case 'drives':
      return 'drive';
    case 'bearings':
      return 'bearing';
  }
  return 'Hardware';
}

export default {
  methods: {
    singularHardwareType,
  }
};