import http from './httpClient';
import Axios from 'axios';

import {FileCategory} from '@animtools/rdx-common';

/**
 * @typedef DownloadZipFile
 * @type {object}
 * @property {number} id - File id
 * @property {string} [version] - File version id
 *
 * @typedef {DownloadZipFile[]} DownloadZipPayload
 */

const endpoint = '/api/';
const api = {
  getCurrentUserInfo() {
    return http.get('/auth/userinfo', { withCredentials: true });
  },
  getUsers() {
    return http.get(endpoint + 'users');
  },

  getUser(userId) {
    return http.get(endpoint + 'users/' + userId);
  },

  getUserFigures(userId) {
    return http.get(endpoint + 'users/' + userId + '/figures');
  },

  addUser(user) {
    return http.post(endpoint + 'users', user);
  },

  editUser(user) {
    return http.put(endpoint + 'users', user);
  },

  getRoles() {
    return http.get(endpoint + 'role');
  },

  addHardware(hardwareType, hardware) {
    return http.post(endpoint + hardwareType, hardware);
  },

  copyHardware(hardwareType, hardware) {
    return http.post(endpoint + hardwareType + '/copy/' + hardware.uuid);
  },

  editHardware(hardwareType, id, changes) {
    return http.put(endpoint + hardwareType + '/' + id, changes);
  },

  deleteHardware(hardwareType, hardware) {
    return http.delete(endpoint + hardwareType + '/' + hardware.uuid);
  },

  getTokens() {
    return http.get(endpoint + 'tokens');
  },

  getAllTokens() {
    return http.get(endpoint + 'tokens/all');
  },

  createToken(token) {
    return http.post(endpoint + 'tokens', token);
  },

  deleteToken(token) {
    return http.delete(endpoint + 'tokens/' + token.id);
  },

  hardwareAdd({commit}, {hardwareType, hardware}) {
    api
        .addHardware(hardwareType, hardware)
        .then(() => {
          commit('setDialog', false);
        })
        .catch((error) => {
          console.log('Error is ', error);
          commit('pushError', error);
        });
  },

  hardwareEdit({commit}, {hardwareType, id, changes}) {
    api
        .editHardware(hardwareType, id, changes)
        .then(() => {
          commit('setDialog', false);
        })
        .catch((error) => {
          commit('pushError', error);
        });
  },

  hardwareDelete({commit}, {hardwareType, hardware}) {
    api.deleteHardware(hardwareType, hardware).catch((error) => {
      commit('pushError', error);
    });
  },

  getReviews() {
    return http.get(endpoint + 'reviews');
  },

  createReview(review) {
    return http.post(endpoint + 'reviews', review);
  },

  approveReview(review) {
    return http.post(endpoint + 'reviews/' + review.uuid + '/approve');
  },

  denyReview(review, reason) {
    return http.post(endpoint + 'reviews/' + review.uuid + '/deny', {
      comments: reason,
    });
  },

  cancelReview(review) {
    return http.post(endpoint + 'reviews/' + review.uuid + '/cancel');
  },

  getAssetsAll() {
    return http.get(endpoint + 'assets');
  },

  addAsset(asset) {
    return http.post(endpoint + 'assets', asset);
  },

  editAsset(id, changes) {
    return http.put(endpoint + 'assets/' + id, changes);
  },

  editAssets(changes) {
    return http.put(endpoint + 'assets', changes);
  },

  deleteAsset(asset) {
    return http.delete(endpoint + 'assets/' + asset.id);
  },

  /**
   * Download an asset file
   *
   * @param {number} assetId
   * @param {import('axios').AxiosRequestConfig} [config] - Axios request config
   */
  downloadAsset(assetId, config) {
    return http.get(`${endpoint}assets/download/${assetId}`, config);
  },

  getFigures() {
    return http.get(endpoint + 'figures');
  },

  getFigure(figureId) {
    return http.get(endpoint + 'figures/' + figureId);
  },

  getFigureMetaData(figureId) {
    return http.get(endpoint + 'figures/metadata/' + figureId);
  },

  editFigure(figureId, changes) {
    return http.put(endpoint + 'figures/' + figureId, changes);
  },

  getFigureHistory(figureId) {
    return http.get(endpoint + 'figures/' + figureId + '/history');
  },

  requestFigurePermissionUpgrade(figureId, permissionLevel, requestFullProject, reason) {
    const body = {requestFullProject: requestFullProject, reason: reason}
    return http.put(endpoint + 'figures/permission/request/' + figureId + '/' + permissionLevel, body);
  },

  getPendingPermissionRequests(userId?: number) {
    return http.get(endpoint + `figures/permission/request${userId ? `?userId=${userId}` : ''}`);
  },

  getPermissionRequest(requestId: number) {
    return http.get(endpoint + `figures/permission/request/${requestId}`);
  },

  approvePendingFigurePermissionRequest(requestId) {
    return http.put(endpoint + 'figures/permission/request/approve/' + requestId);
  },

  denyPendingFigurePermissionRequest(requestId, reasonText) {
    const body = {reason: reasonText}
    return http.put(endpoint + 'figures/permission/request/deny/' + requestId, body);
  },

  /**
   * Download the figure excel sheet file
   *
   * @param {number} figureId
   * @param {import('axios').AxiosRequestConfig} [config] - Axios request config
   */
  figureExcelSheet(figureId, config) {
    return http.get(`${endpoint}figures/excel-sheet/${figureId}`, config);
  },

  getNotificationSettingsByFigure(figureUuid: string) {
    return http.get(`${endpoint}notifications/settings/${figureUuid}`);
  },

  updateFigureNotificationSettingsBulk(payload) {
    return http.put(`${endpoint}notifications/settings`, payload);
  },

  async generatePresignedUrl(data) {
    return await http.post(endpoint + 'file/url', data);
  },

  async addFileMetadata(metadata) {
    return await http.post(endpoint + 'file/metadata', metadata);
  },

  editFileMetadata(metadata) {
    return http.put(endpoint + 'file/metadata', metadata);
  },

  editFileMetadataBulk(filesMetadata) {
    return http.put(endpoint + 'file/metadata/bulk', filesMetadata);
  },

  async lookupFileVersions(data) {
    return await http.post(endpoint + 'file/versions', data);
  },

  addFunction(fn) {
    return http.post(endpoint + 'functions', fn);
  },

  getFunction(functionId) {
    return http.get(endpoint + 'functions/' + functionId);
  },

  getFunctionsMap(version = 'V2') {
    return http.get(endpoint + 'functions/maps?version=' + version);
  },

  getFunctionsForFigure(figureUuid) {
    return http.get(endpoint + 'functions/?figure=' + figureUuid);
  },

  editFunction(uuid, changes) {
    return http.put(endpoint + 'functions/' + uuid, changes);
  },

  editFunctions(changes) {
    return http.put(endpoint + 'functions', changes);
  },

  addActuator(actuator) {
    return http.post(endpoint + 'actuators', actuator);
  },

  getActuatorsForFigure(figureUuid, requestedLinearUnits = 'stored') {
    return http.get(endpoint + `actuators/?requestedLinearUnits=${requestedLinearUnits}&figure=${figureUuid}`);
  },

  getActuatorsMap(version = 'V2') {
    return http.get(endpoint + 'actuators/maps?version=' + version);
  },

  editActuator(uuid, changes) {
    return http.put(endpoint + 'actuators/' + uuid, changes);
  },

  editActuators(changes) {
    return http.put(endpoint + 'actuators', changes);
  },

  deleteActuator(uuid, changes) {
    return http({
      method: 'delete',
      url: endpoint + 'actuators/' + uuid,
      data: {
        message: changes,
      },
    });
  },

  getChangelog(scope) {
    return http.post(endpoint + 'changes/search', {scope});
  },

  getShotgunFilePublishName(metadata) {
    return http.post(endpoint + 'shotgun/getSgFilePublishName', {metadata});
  },

  getShotgunAvailablePipelines(projectId, assetId, setupId, designPhase) {
    if (setupId) {
      return http.get(
          endpoint +
          'shotgun/getSgAvailablePipelines?projectId=' +
          projectId +
          '&assetId=' +
          assetId +
          '&setupId=' +
          setupId +
          '&designPhase=' +
          designPhase,
      );
    } else {
      return http.get(
          endpoint +
          'shotgun/getSgAvailablePipelines?projectId=' +
          projectId +
          '&assetId=' +
          assetId,
      );
    }
  },

  getShotgunAttachments(projectId, entityType, entityId) {
    return http.get(
        `${endpoint}shotgun/attachments/${projectId}/${entityType}/${entityId}`,
    );
  },

  shotgunFileInitialize(metadata) {
    return http.post(endpoint + 'shotgun/initiateFileTransfer', {metadata});
  },

  upload(file, progressCallback) {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('storagePath', file.storagePath);

    let cancel;
    const promise = http.post('/api/file', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      cancelToken: new Axios.CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      }),
      onUploadProgress: function(progressEvent) {
        const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
        );
        if (progressCallback) {
          progressCallback(progress);
        }
      },
    });
    return {promise, cancel};
  },

  async putPresignedUrl(file) {
    const s3Data = {
      key: file.storagePath + file.name,
      requestType: 'put',
    };
    const presignedUrl = await this.generatePresignedUrl(s3Data);
    return presignedUrl.data;
  },

  /* This method uploads to S3 presignedURL */
  async uploadFile(file, url, progressCallback): Promise<any> {
    const headers = {
      'Content-Type': 'application/octet-stream',
    };
    const data = file;
    try {
      const response = await http({
        method: 'PUT',
        url,
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
        headers,
        data,
        timeout: 1800000, // 30 minute timeout for file upload only
        onUploadProgress: function(progressEvent) {
          const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
          );
          if (progressCallback) {
            progressCallback(progress);
          }
        },
      });
      return {response};
    } catch (error) {
      return {error};
    }
  },

  getShotgunDownloadUrl(fileId) {
    return endpoint + 'shotgun/file/' + fileId;
  },

  getDownloadUrl(fileId) {
    return endpoint + 'file/' + fileId;
  },

  readFileAsync(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    });
  },

  getTableSettings() {
    return http.get(endpoint + 'table-settings');
  },

  saveTableSettings(data) {
    return http.put(endpoint + 'table-settings', data);
  },

  getTrackingsForFigure(figureUuid) {
    return http.get(endpoint + 'trackings/?figure=' + figureUuid);
  },

  createTrackings(changes) {
    return http.post(endpoint + 'trackings', changes);
  },

  updateAllProjectsTrackings(changes, projectId) {
    return http.put(endpoint + `trackings/project/${projectId}`, changes);
  },

  editTrackings(changes) {
    return http.put(endpoint + 'trackings', changes);
  },

  getAllChips() {
    return http.get(endpoint + 'chips');
  },

  addChips(chips) {
    return http.post(endpoint + 'chips', chips);
  },

  deleteChip(uuid) {
    return http.delete(endpoint + 'chips/' + uuid);
  },

  insertChipInFigure(chipUuid, figureUuid) {
    return http.put(`${endpoint}figures/${figureUuid}/chips/${chipUuid}`);
  },

  removeChipInFigure(chipUuid, figureUuid) {
    return http.delete(`${endpoint}figures/${figureUuid}/chips/${chipUuid}`);
  },

  insertFavoriteFigure(figureUuid) {
    return http.put(`${endpoint}users/favorite/figures/${figureUuid}`);
  },
  insertFavoriteMotor(hardwareUuid) {
    return http.put(`${endpoint}users/favorite/hardware/motors/${hardwareUuid}`);
  },
  insertFavoriteEncoder(hardwareUuid) {
    return http.put(`${endpoint}users/favorite/hardware/encoders/${hardwareUuid}`);
  },
  insertFavoriteDrive(hardwareUuid) {
    return http.put(`${endpoint}users/favorite/hardware/drives/${hardwareUuid}`);
  },
  insertFavoriteGearbox(hardwareUuid) {
    return http.put(`${endpoint}users/favorite/hardware/gearboxes/${hardwareUuid}`);
  },

  removeFavoriteFigure(figureUuid) {
    return http.delete(`${endpoint}users/favorite/figures/${figureUuid}`);
  },
  removeFavoriteMotor(hardwareUuid) {
    return http.delete(`${endpoint}users/favorite/hardware/motors/${hardwareUuid}`);
  },
  removeFavoriteEncoder(hardwareUuid) {
    return http.delete(`${endpoint}users/favorite/hardware/encoders/${hardwareUuid}`);
  },
  removeFavoriteDrive(hardwareUuid) {
    return http.delete(`${endpoint}users/favorite/hardware/drives/${hardwareUuid}`);
  },
  removeFavoriteGearbox(hardwareUuid) {
    return http.delete(`${endpoint}users/favorite/hardware/gearboxes/${hardwareUuid}`);
  },

  async getVersions(figureUuid, subgroup, fileCategory, name, designPhase) {
    let url = `${endpoint}versions/figure/${figureUuid}`;
    if (subgroup) url += '?subgroup=' + encodeURIComponent(subgroup);
    if (fileCategory) {
      url += url.includes('?') ? '&' : '?';
      url += 'file-category=' + encodeURIComponent(fileCategory);
    }
    if (name) {
      const parsedName = this.parseName(name);
      url += url.includes('?') ? '&' : '?';
      url += 'name=' + encodeURIComponent(parsedName);
    }
    if (designPhase) {
      url += url.includes('?') ? '&' : '?';
      url += 'design-phase=' + encodeURIComponent(designPhase);
    }
    return http.get(url);
  },

  getVersion(versionId) {
    return http.get(`${endpoint}versions/${versionId}`);
  },

  getSubgroups(figureUuid, designPhase) {
    let url = `${endpoint}versions/subgroups?figure-uuid=${figureUuid}`;
    if (designPhase) url += `&design-phase=${designPhase}`;
    return http.get(url);
  },

  getPublishLabels(figureUuid, designPhase) {
    let url = `${endpoint}versions/publishlabels?figure-uuid=${figureUuid}`;
    if (designPhase) url += `&design-phase=${designPhase}`;
    return http.get(url);
  },

  addVersion(version) {
    return http.post(endpoint + 'versions', version);
  },

  editVersion(versionId, version) {
    return http.put(`${endpoint}versions/${versionId}`, version);
  },

  /**
   * Download a file
   *
   * @param {number} fileId
   * @param {import('axios').AxiosRequestConfig} [config] - Axios request config
   */
  getFile(fileId, config) {
    return http.get(`${endpoint}file/${fileId}`, config);
  },

  getFileMetadata(fileId) {
    return http.get(`${endpoint}file/metadata/${fileId}`);
  },

  /**
   * Download a compressed file composed by multiple files
   *
   * @param {DownloadZipFile[]} payload - List of files to download compressed
   * @param {import('axios').AxiosRequestConfig} [config] - Axios request config
   */
  downloadFilesInZip(payload, config?: any) {
    return http.post(`${endpoint}file/download`, payload, config);
  },

  removeFile(fileId) {
    return http.delete(`${endpoint}file/${fileId}`);
  },

  destroyFile(fileId) {
    return http.delete(`${endpoint}file/${fileId}/destroy`);
  },

  getCheckout(figureUuid, type, step, slug) {
    const hasSlug = slug ?
      '/' + type + '/' + step + '/' + slug :
      '/' + type + '/' + step;
    const hasStep = step >= 0 ? hasSlug : '/' + type;
    const hasType = type ? hasStep : '';
    return http.get(`${endpoint}checkout/${figureUuid}${hasType}`);
  },

  saveCheckoutStepDetail(figureUuid, type, step, slug, payload) {
    return http.post(
        `${endpoint}checkout/${figureUuid}/${type}/${step}/${slug}`,
        payload,
    );
  },

  getCheckoutCommentsByStep(figureUuid, stepType, step, commentType = '') {
    const filters = {
      type: commentType,
    };
    const query = new URLSearchParams(filters);
    const queryString = query.toString();

    return http.get(`${endpoint}comments/${figureUuid}/${stepType}/${step}?${queryString}`);
  },

  saveCheckoutComment(payload) {
    return http.post(`${endpoint}comments/`, payload);
  },

  editCheckoutComment(commentId, payload) {
    return http.put(`${endpoint}comments/${commentId}`, payload);
  },

  deleteCheckoutComment(commentId) {
    return http.delete(`${endpoint}comments/${commentId}`);
  },

  saveCommit(figureUuid, type, step, payload) {
    return http.post(
        `${endpoint}checkout/${figureUuid}/${type}/${step}/commit`,
        payload,
    );
  },

  getReferences(figureUuid, type, step) {
    const hasStep =
      step >= 0 && figureUuid && type ?
        `/${figureUuid}/${type}/${step}` :
        `/${figureUuid}/${type}`;
    const hasType = type && figureUuid ? hasStep : '';
    const hasFigureUuid = figureUuid ? hasType : '';
    return http.get(`${endpoint}checkout${hasFigureUuid}/references`);
  },

  getCheckoutHistory(figureUuid, type, step, slug) {
    return http.get(
        `${endpoint}checkout/${figureUuid}/${type}/${step}/${slug}/history`,
    );
  },

  getActuatorFieldHistory(uuid, field) {
    return http.get(`${endpoint}actuators/${uuid}/${field}/history`);
  },

  getActuatorFieldsHistoryBulk(uuid, fields) {
    return http.get(`${endpoint}actuators/${uuid}/history?fields=${fields.join(',')}`);
  },

  getFunctionFieldHistory(uuid: string, field: string) {
    return http.get(`${endpoint}functions/${uuid}/${field}/history`);
  },

  getFunctionFieldsHistoryBulk(uuid, fields) {
    return http.get(`${endpoint}functions/${uuid}/history?fields=${fields.join(',')}`);
  },

  getProgressByType(figureUuid, type) {
    return http.get(
        `${endpoint}checkout/${figureUuid}/${type}/progress`,
    );
  },

  distributeSteps(uuid, action) {
    return http.put(`${endpoint}checkout/${uuid}/${action}`);
  },

  getAstrolabePhotoUrl(sapIds: string[] = []) {
    const joinedIds = sapIds.join(',');
    return http.get(`${endpoint}astrolabe/photo?ids=${joinedIds}`);
  },

  getAstrolabeProfiles(userIds: string[] = []) {
    const joinedIds = userIds.join(',');
    return http.get(`${endpoint}astrolabe/profile?ids=${joinedIds}`);
  },

  getFieldTooltips() {
    return http.get(`${endpoint}fields/tooltips`);
  },

  getDatapickerTemplates() {
    return http.get(`${endpoint}datapicker/templates`);
  },

  deleteDatapickerTemplates(uuid) {
    return http.delete(`${endpoint}datapicker/template/${uuid}`);
  },

  createDatapickerTemplates(newTemplate) {
    return http.post(`${endpoint}datapicker/template`, newTemplate);
  },

  updateDatapickerTemplates(uuid, template) {
    return http.put(`${endpoint}datapicker/template/${uuid}`, template);
  },

  favoriteDatapickerTemplate(templateUuid) {
    return http.post(`${endpoint}datapicker/template/favorite/${templateUuid}`);
  },

  unFavoriteDatapickerTemplate(templateUuid) {
    return http.delete(`${endpoint}datapicker/template/favorite/${templateUuid}`);
  },

  setRecentlyViewedFigure(figureUuid) {
    return http.put(`${endpoint}users/figure-view/${figureUuid}`);
  },

  getNotifications() {
    return http.get(`${endpoint}notifications/center`);
  },

  deleteNotification(notificationId) {
    return http.delete(`${endpoint}notifications/center/${notificationId}`);
  },

  markNotificationAsRead(notificationId) {
    return http.put(`${endpoint}notifications/center/markAsRead/${notificationId}`);
  },

  markAllNotificationsAsRead() {
    return http.put(`${endpoint}notifications/center/markAsRead`);
  },

  deleteAllNotifications() {
    return http.delete(`${endpoint}notifications/center`);
  },

  markNotificationAsUnread(notificationId) {
    return http.put(`${endpoint}notifications/center/markAsUnread/${notificationId}`);
  },

  getHardwareHistory(hardwareType: string, hardwareUuid: string) {
    return http.get(`${endpoint}${hardwareType}/${hardwareUuid}/history`);
  },

  getHardwareFieldHistory(hardwareType: string, hardwareUuid: string, field: string) {
    return http.get(`${endpoint}${hardwareType}/${hardwareUuid}/${field}/history`);
  },

  getFigureHardwareUsage(hardwareType: string, hardwareUuid: string) {
    return http.get(`${endpoint}${hardwareType}/${hardwareUuid}/used-in`);
  },

  parseName(name: string, fileCategory: string) {
    const isPymFile = fileCategory === FileCategory.rdx;
    return isPymFile
      ? name.replace(/(-pym|-rdx)(_p[0-9]{3})*(\.[^.]+)$/, '')
      : name.replace(/_p[0-9]{3}(\.[^.]+)$/, '$1');
  }
};

export default api;
