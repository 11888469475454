import Vue from 'vue'
import { 
  performUnitConversion, 
  getStoredUnit, 
  getDefaultUnit,
  getUnitType,
  getUnits,
  isNumber, 
  ACTUATOR_FORMULAS, 
  IS_ACTUATOR 
} from '@animtools/rdx-common';
import { unitsMap } from '../../constants/units';

export const unitsModule = {
  namespaced: true,

  state: {
    units: unitsMap,
    currentSelectedUnits: {},
  },

  getters: {
    getCurrentSelectedUnit: (state) => (item, field, key) => {
      const isLinear = IS_ACTUATOR(item) ? ACTUATOR_FORMULAS.isLinear(item) : item?.isLinear || item?.linear;
      const defaultUnit = getDefaultUnit(field, isLinear);
      if (!key) {
        key = `${item.uuid}_${field.value}`;
      }
      let storedSelectedUnitName = state.currentSelectedUnits[key];

      if (storedSelectedUnitName) {
        // Check to see if the selected unit is still available
        // Ex: If we are switching from rotary to linear, certain units may no longer apply
        const currentUnitType = getUnitType(field, item);
        const fieldUnits = getUnits(unitsMap, currentUnitType);
        const fieldDisplaysAllUnitTypes = currentUnitType?.startsWith('multiple_');
        if (fieldDisplaysAllUnitTypes) {
          // If the current field displays both rotary & linear units, the stored unit name will be
          // "rotary_{unitname}" or "linear_{unitname}"
          storedSelectedUnitName = storedSelectedUnitName.split('_')[1];
        }
        const selectedUnit = fieldUnits.find((unit) => unit.text === storedSelectedUnitName);
        // If the selected unit is no longer available, fall back to default unit
        storedSelectedUnitName = selectedUnit ? selectedUnit.text : null;
      }

      const selectedUnitName = storedSelectedUnitName ? storedSelectedUnitName : defaultUnit?.text;
      return selectedUnitName;
    },
    getSelectedUnitFieldValue: (state, getters) => (item, field, value?: any, unitKey?: string) => {
      // 1. Collect Unit Type Information
      const selectedUnitName = getters.getCurrentSelectedUnit(item, field, unitKey);
      const storedUnit = getStoredUnit(field, item);

      const parentItem = item;
      if (field.parent) {
        item = item[field.parent];
      }

      if (item == null) {
        return null;
      }

      // 2. Get the value from the item
      let itemValue = value ?? item[field.value];

      // If a specific override value is not provided, calculate the value
      if (field.calculation && !value) {
        itemValue = field.calculation(item, parentItem);
      }
      if (field.multiplier) {
        itemValue = field.multiplier * itemValue;
      }

      // 3. Convert to the request units
      return performUnitConversion(state.units, item, field, itemValue, storedUnit.text, selectedUnitName, false);
    },
    getStoredUnitFieldValue: (state, getters) => (item, field, value) => {
      if (!field.unitType) {
        return value;
      }
      if (value === null || !isNumber(value)) {
        return null;
      }
      
      const selectedUnitName = getters.getCurrentSelectedUnit(item, field);
      const storedUnit = getStoredUnit(field, item);
      return performUnitConversion(state.units, item, field, value, selectedUnitName, storedUnit.text, true);
    },
  },

  actions: {},

  mutations: {
    // DEPRECATED - used by RDXTable
    setUnit(state, changes) {
      if (changes.header.originalUnitType) {
        state.units[changes.type].selected = changes.value;
      } else if (changes.header.parentValue) {
        state.units[
          `${changes.header.parentValue}_${changes.header.value}_${changes.type}`
        ].selected = changes.value;
      } else {
        const currentUnit = state.units[`${changes.header.value}_${changes.type}`];
        const changeIsInCurrentUnitOptions = currentUnit.options
            .some(option => option.value === changes.value.value);

        if (changeIsInCurrentUnitOptions) {
          currentUnit.selected = changes.value;
        }
      }
    },
    setSelectedUnit(state, { item, field, unit, key }) {
      if (!key) {
        key = `${item.uuid}_${field.value}`;
      }
      const currentUnitTypeName = getUnitType(field, item);
      const fieldUnits = getUnits(unitsMap, currentUnitTypeName);
      const desiredUnit = fieldUnits.find((u) => u.value === unit);
      if (desiredUnit) {
        Vue.set(state.currentSelectedUnits, key, desiredUnit.value);
      }
    },
  },
}
